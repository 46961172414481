import React from 'react'
import styled from 'styled-components'

import Theme from '../styles/Theme'

const Wrapper = styled.div`
  display: grid;
  height: 100vh;
  grid-template: 1fr 1fr 1fr / 1fr 1fr 1fr;
`

export default function Layout(props) {
  return(
    <>
      <Theme>
        <Wrapper>
          {props.children}
        </Wrapper>
      </Theme>
    </>
  )
}