import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

import './font.css'
import './styles.css'

export const theme = {}

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: var(--black);
    color: var(--light);
    width: 100%;
    height: auto;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    text-rendering: optimizeLegibility;
  }
  * {
    font-family: var(--font-family-sans-serif);
    color: var(--light);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  #___gatsby {
    width: 100%;
    height: 100%;
  }
  .grid {
    display: grid;
    grid-gap: 1em;
    grid-template-rows: 1fr;
    align-items: center;
    &.col-1 {
      grid-template-columns: repeat(1, 1fr);
    }
    &.col-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  section.main {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    justify-items: start;
    margin: 1rem auto;
    padding: 2rem;
  }

  h1 {
    font-variation-settings: 
      "MONO" 1, "CASL" 0, "wght" 320 , var(--slnt), "ital" 0;
    letter-spacing: 0.4px;
  }
  h2 {
    font-variation-settings: 
      var(--mono),"CASL" 1,"wght" 640,var(--slnt), "ital" 1;
    letter-spacing: 0.4px;
  }
  a {
    font-weight: 300;
    transition: 256ms;
    text-decoration: underline dotted;
    font-variation-settings: 'CASL' 0;
    text-decoration: underline 1px dotted;
    :hover {
      font-weight: 720;
      font-variation-settings: 'CASL' 1;
    }
  }
  h1,
  h2,
  h3 {
    margin: 1rem 0;
  }
  h2 {
    font-family: var(--font-family-serif);
  }
  p {
    margin-bottom: .8rem;
  }
  strong {
    font-variation-settings:
      "wght" 724;
  }
  em {
    font-variation-settings:
      "slnt" -15,
      "ital" .5;
  }
  hr {
    color: var(--gray-dark);
    margin: .8rem 0;
  }
  blockquote {
    padding: 0 .8rem;
    background: #F6F6F633;
    border-radius: 5px;
    border-left: 4px solid var(--light);
    p {
      padding: 1rem 0;
      &:before {
        content: '"';
      }
      &:after {
        content: '"';
      }
    }
  }
  ul, ol {
    padding-inline-start: 40px;
  }
  li {
    display: list-item;
    text-align: match-parent;
    > p {
      margin: 0;
    }
  }
  code {
    background-color: #F6F6F633;
    border-radius: 3px;
    font-family: var(--font-family-monospace);
    padding: 0 3px;
    font-size: .8rem;
  }
  pre {
    display: block;
    background-color: #F6F6F633;
    font-size: .8rem;
    white-space: pre;
    margin-left: 1em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    code {
      background-color: transparent;
    }
  }
  img, video {
    max-width: 100%;
    height: auto;
  }
`

export default function Theme(props) {
  return (
    <>
      <GlobalStyle darkmode />
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </>
  )
}

Theme.propTypes = {
  children: PropTypes.node,
}
